















































































































































































































































































































































































































































































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { InputSetups } from '../../mixins/input-setups'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import BindChannelByCode, { BindChannelState } from '@/components/chat/BindChannelByCode.vue'
import { ActiveBindMethod } from '@/components/chat/ChannelBinding.vue'
import Placeholders from "@/mixins/placeholders/placeholders";
import { BRAND_TAG } from '@/includes/constants'

import ConfigGroup from 'piramis-base-components/src/components/ConfigGroup/ConfigGroup.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types';
import Tags from "piramis-base-components/src/components/Tags/Tags.vue";
import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { atSignedLogin, tgLoginLink } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'

import { Component, Mixins } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import ModulesScreensWrapper from "@/components/Modules/modules/ModulesScreensWrapper.vue";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

@Component({
  'components': {
    ModulesScreensWrapper,
    BindChannelByCode,
    CenteredColumnLayout,
    PageTitle,
    ConfigGroup,
    ValidationObserver,
    Tags,
    ConfigField
  },
  data() {
    return {
      ActiveBindMethod,
      MediaEditorMode,
      BRAND_TAG,
      StepUnit
    }
  }
})
export default class FaceControlPage extends Mixins(UseFields, InputSetups, TariffsTagsHelper, Placeholders) {
  moderationExtra: Array<ModuleTypesEnum> = [
    ModuleTypesEnum.FacecontrolHandlerModule,
    ModuleTypesEnum.EnterBindedChannelHandlerModule,
    ModuleTypesEnum.MessageBindedChannelHandlerModule,
    ModuleTypesEnum.NewUsersLimitHandlerModule,
  ]

  currentView = 0

  channel = {
    channelId: ''
  }

  activeMethod = ActiveBindMethod.Self

  channelBindModal = false

  get banTypeOptions() {
    return [
      {
        label: this.$t("param_do_not_punish"),
        value: "none",
      }, {
        label: this.$t(`facecontrol_username_ban_type_param_remove_from_chat_forever`),
        value: "ban",
      },
      {
        label: this.$t(`facecontrol_username_ban_type_param_remove_from_chat_for_while`),
        value: "kick",
      },
      ...(this.chat as any).config.facecontrol_username_ban_type === "mute" ? [ {
        label: this.$t("silent_mode"),
        value: "mute",
      } ] : [],
    ]
  }

  get chat() {
    return this.$store.state.chatState.chat
  }

  setRecheckTime(arg: FieldData): FieldData {
    arg.setter = (val) => {
      if (isNaN(val)) {
        arg.model[arg.key] = 600
      } else {
        if (val >=600 || val === 0) {
          arg.model[arg.key] = val
        } else {
          arg.model[arg.key] = 600
        }
        // if (val < 600) {
        //   arg.model[arg.key] = 600
        // } else {
        //   arg.model[arg.key] = val
        // }
      }
    }

    return arg
  }

  channelBindWarning(value: number | string): void {
    this.$confirm({
      title: this.$t('pi_warning').toString(),
      content: this.$t('bind_journal_save_message').toString(),
      okText: this.$t('do_save_chat_config').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        this.$store.commit('EXEC_CALLBACK_IN_STORE', {
          'callback': () => this.chat.config.facecontrol_binded_channel_id = value
        })

        this.$store.dispatch('saveChatConfig')
      }
    })
  }

  handleOnChannelBind(data: BindChannelState): void {
    if (data.channel_id) {
      this.channelBindModal = false
      this.channelBindWarning(data.channel_id)
    }
  }

  get bindChannelInfo(): string {
    if (this.chat.brand) {
      return this.$t('bind_face_control_channel_code_info_2', { '0': atSignedLogin(this.chat.brand.botname), '1': tgLoginLink(this.chat.brand.botname) }).toString()
    } else {
      return this.$t('bind_face_control_channel_code_info_2', { '0': `@ChatKeeperBot`, '1': `https://t.me/chatkeeperbot` }).toString()
    }
  }

  bindFaceControlChannel(): void {
    this.channelBindModal = false
    this.channelBindWarning(Number.parseInt(this.channel.channelId))
  }

  unbind(): void {
    this.$confirm({
      title: this.$t('pi_warning').toString(),
      content: this.$t('unbind_facecontrol_save_message').toString(),
      okText: this.$t('do_save_chat_config').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        this.$store.commit('EXEC_CALLBACK_IN_STORE', {
          'callback': () => this.chat.config.facecontrol_binded_channel_id = ''
        })
        this.$store.dispatch('saveChatConfig')
        this.activeMethod = ActiveBindMethod.Self
      }
    })
  }
}
